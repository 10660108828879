import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AlertDialogModel, AlertDialogComponent } from 'src/app/partial/layout/alert-dialog/alert-dialog.component';
import { ProfilerService } from 'src/app/services/profiler.service';
import { ProfilerDbService } from 'src/app/services/profiler-db.service';
import { AuthService } from 'src/app/services/auth.service';




@Component({
  selector: 'app-profile-search',
  templateUrl: './profile-search.component.html',
  styleUrls: ['./profile-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileSearchComponent implements OnInit {
  tagsvalue: any;
  selectedTab: string = '';
  searchform = this.initilizeSearchform()
  countryCode: any;
  validateMobile: boolean = true;
  tagList: string[] = [];
  selectedLists: any = []
  repositoryName: any;
  repositoryId: any;
  dialogTitle: any;
  multiNoSearch: boolean = false;
  multiEmailSearch: boolean = false;
  multiSocialMediaSearch: boolean = false;
  multiNationIdSearch: boolean = false;
  multiIpAddressSearch: boolean = false;
  multiUpiAddressSearch:boolean=false;
  counter: number;

  constructor(
    private profilerService: ProfilerService,
    private profilerDb: ProfilerDbService,
    private dialog: MatDialog,
    private authService: AuthService,
    private fb: FormBuilder,
    private profilerDB: ProfilerDbService,
    private router: Router,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ProfileSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dialogDataModule) {
    this.dialogRef.disableClose = true;
    this.repositoryId = data.repositoryId
    this.repositoryName = data.repositoryName;
    this.dialogTitle = data.title
    this.selectedTab = 'name';
    
  }

  ngOnInit() {
    console.log(this.data?.dataList)
    if(this.data?.dataList !== undefined && this.data?.dataList !== 0){  
     let emailItem = '';
     let phoneItem = '';
     let phoneArray = [];
     let emailArray = []
     this.data.dataList.forEach(ele =>{ 
      if(ele.Email !== undefined){
        emailItem= emailItem+"\n"+ele.Email;
        emailArray.push(ele.Email);
         
      }
      else if(ele.phone !== undefined){
        phoneItem = phoneItem+"\n"+ele.phone;
        phoneArray.push(ele.phone);
      }

     });
    if(emailArray.length > 1){
      this.multiEmailSearch= true;
    }
    if(phoneArray.length > 1){
      this.multiNoSearch = true;
    }
    this.searchform.controls['repositoryName'].setValue(this.data?.repositoryName);
    this.searchform.controls['searchKeywordEmail'].setValue(emailItem);
    this.searchform.controls['searchKeywordPhone'].setValue(phoneItem);

  }
    
  }


  multiMode(e: any, type: string) {
    if (type === 'mobile') {
      this.multiNoSearch = e.checked;
      this.searchform.patchValue({ searchKeywordPhone: "" });    }
    if (type === 'email') this.multiEmailSearch = e.checked;
    if (type === 'socialmedia') this.multiSocialMediaSearch = e.checked;
    if (type === 'nationalid') this.multiNationIdSearch = e.checked;
    if (type === 'ip') this.multiIpAddressSearch = e.checked;
  }


  @ViewChild('mobileno') mobile: ElementRef;
  search(obj) {
    this.disableToSubmit = false;
    obj["countryCode"] = '+' + this.countryCode;
    obj["userId"] = atob(localStorage.getItem('USR_ID')) || '';    
    if (this.multiNoSearch){ 
      obj["searchKeywordPhone"] = obj["searchKeywordPhone"].split('\n');
    }else{
      obj["searchKeywordPhone"] = obj["searchKeywordPhone"]?.replace(obj["countryCode"], obj["countryCode"]+'-')
      obj["searchKeywordPhone"] = [obj["searchKeywordPhone"]].filter((d:any)=> d !== '');
      let phone = document.querySelector('[type="tel"]') as HTMLInputElement;
      phone.value = '';      
    }
    if (this.multiEmailSearch) obj["searchKeywordEmail"] = obj["searchKeywordEmail"].split('\n');
    else obj["searchKeywordEmail"] = [obj["searchKeywordEmail"]].filter((d:any)=> d !== '');
    
    if (this.multiSocialMediaSearch) obj["searchKeywordSocialMediaId"] = obj["searchKeywordSocialMediaId"].split('\n');
    else obj["searchKeywordSocialMediaId"] = [obj["searchKeywordSocialMediaId"]].filter((d:any)=> d !== '');
    
    if (this.multiNationIdSearch) obj["searchKeywordNationalId"] = obj["searchKeywordNationalId"].split('\n');
    else  obj["searchKeywordNationalId"] = [obj["searchKeywordNationalId"]].filter((d:any)=> d !== '');
    
    if (this.multiIpAddressSearch) obj["searchKeywordIpAddress"] = obj["searchKeywordIpAddress"].split('\n');
    else  obj["searchKeywordIpAddress"] = [obj["searchKeywordIpAddress"]].filter((d:any)=> d !== '');
    if (this.multiIpAddressSearch) obj["searchKeywordUpiId"] = obj["searchKeywordUpiId"].split('\n');
    else  obj["searchKeywordUpiId"] = [obj["searchKeywordUpiId"]].filter((d:any)=> d !== '');

    this.profilerDb.addKeywordToSearchList(obj).subscribe(
      (res) => {
        if (res["type"] == "success") {
          this.profilerService.refreshHistory.next(true);
          this.dialog.closeAll();
          this.router.navigate(['/def/profile-finder/search-history']);
          this.searchform.get('searchKeywordPhone').setErrors(null)
        } else {
          this.toastr.error(res["message"], 'Error!');
        }
      },
      (err) => console.log(err),
      () => this.disableToSubmit = true
    )
  }


 


  imageList: any = [];
  totalEmailCount: number = 4;
  totalPhoneCount: number = 4
  totalImageCount: number = 5;
  disableToSubmit: boolean = false;
/*REMOVE PHONE/EMAIL AND VALIDATE FORMS*/
  removeItem(itemLists: any, index: number) {
    itemLists.splice(index, 1);
    // this.validateform();
  }

  isSpace(event) {
    if (event.which === 32 && !event.target.value)
      event.preventDefault();
  }

  showAlert(msg, isAllClose) {
    const dialogData = new AlertDialogModel('Alert', msg)
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '350px',
      maxHeight: '400px',
      data: dialogData
    })
    if (isAllClose) dialogRef.afterClosed().subscribe(res => this.dialog.closeAll());
  }


  countryChange(country: any) {
    this.countryCode = country.dialCode;
  }


  // isEditMode: boolean = false;
  isMobilefieldEdited: boolean = false;
  mobileValue: string = "";
  fourMondatoryfield: any = {}
  repositoryById: any = {}

  numberOnly(event, maxlength: string): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (maxlength?.length > 5 && maxlength?.length > 18) return false;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  get searchFormControl() {
    return this.searchform.controls;
  }
  initilizeSearchform() {
    const ipPattern = 
    "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
    const upiPattern: RegExp = /^[a-zA-Z0-9._@-]{1,256}$/
    return new FormGroup({
      repositoryName: new FormControl('', [Validators.required]),
      searchKeywordPhone: new FormControl(''),
      searchKeywordEmail: new FormControl(''),
      searchKeywordSocialMediaId: new FormControl(''),
      searchKeywordNationalId: new FormControl('',Validators.pattern('^[a-zA-Z0-9]$')),
      searchKeywordIpAddress: new FormControl('',Validators.pattern(ipPattern)),
      searchKeywordUpiId: new FormControl('',Validators.pattern(upiPattern)),
      description: new FormControl(''),
    });
  }
}
export class dialogDataModule {
  dataList: any;
  constructor(public repositoryId: any, public repositoryName: any, public title: any, dataList:any) { }
}
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-alert-dialog',
  template: `
  <h1 mat-dialog-title class="mat-dialog-title">
  {{title}}
</h1>

<div mat-dialog-content>
  <p class="mb-4">{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button   cdkFocusInitial color="primary" (click)="onClose()">Ok</button>
 </div>
  `,
  styles: [
  ]
})
export class AlertDialogComponent  {
  title: string;
  message: string;

   constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel) {   
    this.dialogRef.disableClose = true;
    this.title = data.title;
    this.message = data.message;
  } 

  onClose(): void {   
    this.dialogRef.close(true);   
  }

}

export class AlertDialogModel {

  constructor(public title: string, public message: string) {
  }

}

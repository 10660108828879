<div class="fixed-layout ">
  <header class="topbar" [class.mini]="isMiniSidebar">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
      <div class="navbar-header">
        <!-- <a class="navbar-brand  hidden-xs-down hidden-sm-down" (click)="navigateToHome()" href="javascript:void(0)">
                <img src="./assets/images/privacy-logo-mini.png" alt="" class="dark-logo" />
            </a> -->
        <a routerLink="/def/dashboard" class=" logotxt">
          <img src="./assets/images/privacy-logo.png" alt="Patrol Privacy" />
        </a>
      </div>
      <ul class="navbar-nav me-auto head-width-set">
      <li class="nav-item">
        <a class="nav-link sidebartoggler waves-effect waves-dark" (click)="toggleSidebar()"
          href="javascript:void(0)">
          <span class="mobilemenu ">
            <span class="bar bar_1"></span>
            <span class="bar bar_2"></span>
            <span class="bar bar_3"></span>
          </span>
        </a>
      </li>

        <!-- </ul>
        <ul class="navbar-nav me-auto head-width-set">     -->
        <li class="nav-item buy-credits mx-auto">
          <!-- <button type="button" class="btn waves-effect waves-light mx-auto ">
                <a routerLink="/def/user/buy-credit" class="btn buy_credits waves-effect waves-light btn-info btn-sm mx-auto"> Buy Credits</a>
            </button>  -->
          <button type="button" class="btn waves-effect waves-light btn-outline-info btn-sm mx-auto"
            (click)="openDialog()"> New Search</button>
          <!--  -->
        </li>
        <!-- </ul>
        <ul  (clickElsewhere)="showProfilenav = false" class="navbar-nav my-lg-0 mx-lg-5">   -->

        <li *ngIf="isAdmin" class="nav-item dropdown">
            <!-- <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class=" fas fa-bell" data-toggle="tooltip" data-placement="bottom" matTooltip="Notifications"
                aria-label="Button that displays a tooltip when focused or hovered over"></i>
                <div class="notify"> <span class="heartbit">1</span> <span class="point"></span> </div>
            </a> -->
            <!-- <span
            class="position-absolute topbar-badge fs-12 translate-middle badge rounded-pill bg-danger">3<span
                class="visually-hidden">unread messages</span></span>
           <div class="dropdown-menu dropdown-menu-end mailbox animated bounceInDown">
                <ul>
                    <li>
                        <div class="drop-title"> Notifications </div>
                    </li>
                </ul>
            </div> -->
            <button (click)="navigateTo('/def/admin/pending-approval','approval Pending')" type="button" class="mt-2 btn btn-icon btn-topbar btn-ghost-secondary rounded-circle shadow-none"
            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false" ngbDropdownToggle>
            <i class='far fa-bell  text-white' style="font-size: 22px;"></i>
            <span
                class="position-absolute topbar-badge fs-12 translate-middle badge rounded-pill bg-danger">{{pendingApprovalUserList?.total}}</span>
        </button>
        </li>
        <li (clickElsewhere)="showProfilenav = false" class="nav-item dropdown u-pro">
          <a (click)="showProfilenav = !showProfilenav"
            class="nav-link dropdown-toggle waves-effect waves-dark profile-pic" href="javascript:void(0)">
            <i class="fas fa-user admin-icon"> </i>
            <span class="hidden-md-down text-white"> {{sharingService.USR_NAME?sharingService.USR_NAME:'Profile'}} <i
                class="fa fa-angle-down"></i>
            </span>
          </a>
          <div [style.display]="showProfilenav?'block':'none'" class="dropdown-menu dropdown-menu-end animated flipInY">
            <a routerLink="/def/user/profile" class="dropdown-item"><i class="fas fa-edit"></i> View Details</a>
            <a routerLink="/def/user/change-password" class="dropdown-item"><i class="fa fa-key"></i> Change
              Password</a>
            <a href="javascript:void(0)" (click)="logOut()" class="dropdown-item"><i class="fa fa-power-off"></i> Log
              Out</a>
          </div>
        </li>
      </ul>
    </nav>
  </header>
</div>

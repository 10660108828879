<div class="home-one">
    <div class="page-wrapper landing">



        <!-- Main Header-->
        <header class="main-header header-one">
            <div class="header-upper">
                <div class="auto-container">
                    <div class="main-box clearfix">
                        <div class="logo-box clearfix">
                            <div class="logo"><a routerLink="/" title=""><img src="./assets/images/privacy-logo.png"
                                        alt="" title=""></a></div>
                        </div>

                        <div class="nav-box clearfix">
                            <div class="nav-outer clearfix">
                                <nav class="main-menu">
                                </nav>
                            </div>
                            <div class="links-box clearfix">
                                <div class="link"><a routerLink="/ac/login" class="theme-btn btn-style-one"><span
                                            class="txt">Login</span></a></div>
                            </div>
                            <div class="nav-toggler">
                                <button class="hidden-bar-opener"><span class="icon"><i
                                            class="fa-regular fa-bars"></i></span></button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </header>
        <!--End Main Header -->




        <!--Intro Section-->
        <section class="intro-section" id="intro-section">
            <div class="col-12 analysis-zoom marque-css text-center" *ngIf="!closePopup">Register Now & Avail 5 Credit Free <a style="margin-left: 30px;margin-right: 30px" class="btn" (click)="openPopup()"><span>Click Here !</span></a>  Register Now & Avail 5 Credit Free</div>
            <!-- <marquee  class="marque-css col-12" behavior="scroll" onmouseover="this.stop();" onmouseout="this.start();"> <div  class="col-4 analysis-zoom">Register Now & Avail 5 Credit Free    <a style="margin-left: 30px;margin-right: 30px" class="btn" (click)="openPopup()"><span>Click Here !</span></a>  Register Now & Avail 5 Credit Free</div>   -->
                
           <!-- </marquee> -->
            <div class="auto-container">
                <div class="row clearfix">
                    <!--Text Column-->
                    <div class="text-col col-lg-6 col-md-12 col-sm-12">
                        <div class="inner">
                            
                            <div class="title-box">

                                <h2>Take Your Personal Security to the next level.</h2>
                            </div>
                            <div class="about">
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-users"></span></div>
                                        <h5>Discover your data's fate</h5>
                                        <div class="text">Patrol Privacy helps you find out if your personal information
                                            has been compromised."</div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-globe"></span></div>
                                        <h5>Take control of your online identity</h5>
                                        <div class="text">Use Patrol Privacy to see if your data has been breached.
                                        </div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-eye"></span></div>
                                        <h5>Be aware, be informed</h5>
                                        <div class="text">Use Patrol Privacy to check if your information is part of a
                                            data breach.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Image Column-->
                    <div class="image-col col-lg-6 col-md-12 col-sm-12">
                        <div class="inner">
                            <div class="image ss">
                                <img src="./assets/landing/images/globe-1.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 <!--Blog Section Start-->

         <!-- <section>
            <div class="blogs p-4 m-2">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="blog-card p-4">
            
                                <div class="blog-box pe-2 py-2 row">
                                    <div class="col-md-4"><img src="./assets/images/security.png" class="img-responsive"></div>
                                    <div class="col-md-8 pt-3">
                                        
                                      <h1>Radicalisation on social media</h1>
                                      <p>Radicalisation on social media is one of the major concerns in this digital age. phrases specially to attract like-minded individuals.</p>
                                      <h5><i class="fa fa-bookmark"></i> Blog, Cyber Security Service Provider</h5>
                                      <div class="row"> 
                                        <a href="" class="btn read-morer" routerLink="/ac/view-blog-details">learn more</a>
                                      </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="blog-card p-4">
            
                                <div class="blog-box pe-2 py-2 row">
                                    <div class="col-md-4"><img src="./assets/images/security.png" class="img-responsive"></div>
                                    <div class="col-md-8 pt-3">
                                        
                                      <h1>Radicalisation on social media</h1>
                                      <p>Radicalisation on social media is one of the major concerns in this digital age. phrases specially to attract like-minded individuals.</p>
                                      <h5><i class="fa fa-bookmark"></i> Blog, Cyber Security Service Provider</h5>
                                      <div class="row"> 
                                        <a href="" class="btn read-morer" routerLink="/ac/view-blog-details">learn more</a>
                                      </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  -->

        <!--Blog Section End-->

        <!--Why Section-->
        <section class="why-section" id="why-token">
            <div class="auto-container">
                <div class="title-box">
                    <h6 class="subtitle"><span>Why choose us</span></h6>
                </div>
                <div class="row clearfix">
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icons8-mail.svg"
                                        alt=""></span></div>
                            <h4>We See, we let you knows</h4>
                            <div class="text">Stay notified with Patrol Privacy. Sign up for alerts when your email is
                                found in future data breaches.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-2.svg"
                                        alt=""></span></div>
                            <h4>Find it Fast</h4>
                            <div class="text">Use Patrol Privacy's search feature to quickly check if your information
                                has been compromised.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-3.svg"
                                        alt=""></span></div>
                            <h4>Get your info when no one else can</h4>
                            <div class="text">Research made easy. Patrol Privacy's database of breaches allows for easy
                                identification of key social IDs.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-4.svg"
                                        alt=""></span></div>
                            <h4>Better Suit Up</h4>
                            <div class="text">Comprehensive coverage. Patrol Privacy covers personal, corporate, and
                                organizational data to ensure you're always aware of potential breaches.</div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!--Documents Section-->
        <section class="docs-section" id="documents">
            <div class="auto-container">
                <div class="inner-container">
                    <div class="row clearfix">
                        <!--Text Column-->
                        <div class="text-col col-lg-6 col-md-12 col-sm-12">
                            <div class="inner">
                                <div class="title-box">
                                    <h2>Take care of your personal privacy</h2>
                                    <div class="text-content text">A website that allows Internet users to find out if
                                        their personal, corporate, or organizational data has been compromised as a
                                        result of data breaches.</div>
                                </div>

                            </div>
                        </div>
                        <!--Image Column-->
                        <div class="image-col col-lg-6 col-md-12 col-sm-12">
                            <div class="inner">
                                <div class="images">
                                    <img src="./assets/landing/images/f-image-1.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>









        <!--How It Works-->
        <section class="how-it-works">
            <div class="bg-layer-right" style="background-image: url('images/background/how-pattern-1.png');"></div>
            <div class="bg-layer-center" style="background-image: url('images/background/how-pattern-2.png');"></div>
            <div class="bg-layer-two"></div>
            <div class="auto-container">
                <div class="title-box centered">
                    <h6 class="subtitle"><span>How it works</span></h6>
                    <h2>Features & Innovations</h2>
                </div>

                <div class="carousel-box">
                    <div class="pagers-box">
                        <div class="pager-one clearfix">
                            <a href="" class="pager-item" data-slide-index="0">
                                <div class="icon-box"><i class="fa-solid fa-shield-check"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="1">
                                <div class="icon-box"><i class="fa-solid fa-file-shield"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="2">
                                <div class="icon-box"><i class="fa-solid fa-fingerprint"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="3">
                                <div class="icon-box"><i class="fa-solid fa-circle-dollar"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="4">
                                <div class="icon-box"><i class="fa-solid fa-coins"></i></div>
                            </a>
                        </div>
                    </div>
                    <div class="slider-box">
                        <div class="how-work-carousel" id="how-work-carousel">
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Assists in preventing account compromise</h5>
                                    <div class="text">• A trail-blazing database maintained securely by Patrol Privacy,
                                        a rich solution that will allow your enterprise security to go next level.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Next Generation solution to prevent compromises</h5>
                                    <div class="text">• Keeping track of Deep-Web/Dark-Web leaks in real-time.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Assists in the investigation of identity theft</h5>
                                    <div class="text">• Use the Patrol Privacy cyber intelligence dataset to detect
                                        potential identity fraud.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Low-Cost </h5>
                                    <div class="text">• We are not pricey and do not charge for the majority of our
                                        services. People should not have to pay for security to feel secure on the
                                        internet.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Private Data-base</h5>
                                    <div class="text">• Patrol Privacy is constantly obtaining new and private datasets
                                        that other services simply do not have. We are always the first to respond.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!--Main Footer-->
        <footer class="main-footer">

            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="inner">
                        <div class="copyright">Copyright &copy; 2022. All rights reserved by privacy petrol.</div>
                    </div>
                </div>
            </div>
        </footer>

    </div>

    <div class="scroll-to-top scroll-to-target" data-target="html"><span class="icon"><img
                src="./assets/landing/images/arrow-up.svg" alt="" title="Go To Top"></span></div>

























    <div class="page-wrapper d-none">
        <!-- Main Header-->
        <header class="main-header header-one">
            <!-- Header Upper -->
            <div class="header-upper">
                <div class="auto-container">
                    <!-- Main Box -->
                    <div class="main-box clearfix">
                        <!--Logo-->
                        <div class="logo-box clearfix">
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="logo"><a routerLink="/" title=""><img src="./assets/images/privacy-logo.jpg"
                                            alt="" title=""></a></div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="link floatRight"><a routerLink="/ac/login"
                                        class="theme-btn btn-large btn-style-one"><span class="txt"><i
                                                class="fas fa-sign-in-alt"> </i> Login</span></a></div>
                            </div>
                        </div>

                        <div class="nav-box clearfix">
                            <div class="links-box clearfix">
                                <!-- <div class="link"><a routerLink="/ac/login" class="theme-btn btn-style-one"><span class="txt">Login/Sign up</span></a></div> -->
                                <!-- <div class="link"><a href="#" class="theme-btn btn-style-two"><span class="txt">Login</span></a></div> -->
                            </div>

                            <!-- Hidden Nav Toggler -->
                            <!-- <div class="nav-toggler">
                            <button class="hidden-bar-opener"><span class="icon"><i class="fa-regular fa-bars"></i></span></button>
                        </div> -->

                        </div>

                    </div>
                </div>
            </div>
        </header>
        <!--End Main Header -->





        <!--Documents Section-->
        <section class="docs-section" id="documents">
            <div class="auto-container">
                <div class="inner-container">
                    <div class="row clearfix">
                        <!--Text Column-->
                        <div class="text-col col-lg-6 col-md-12 col-sm-12">
                            <div class="inner">
                                <div class="title-box m-t-4">
                                    <h2>Take care of your personal privacy</h2>
                                    <div class="text-content text">A website that allows Internet users to find out if
                                        their personal, corporate, or organizational data has been compromised as a
                                        result of data breaches.</div>
                                </div>
                            </div>
                        </div>
                        <!--Image Column-->
                        <div class="image-col col-lg-6 col-md-12 col-sm-12">
                            <div class="inner">
                                <div class="image animated_hand">
                                    <img src="./assets/landing/images/f-image-1.jpg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <!--Why Section-->
        <section class="why-section" id="why-token">
            <div class="auto-container">
                <div class="title-box">
                    <h6 class="subtitle"><span>Why choose us</span></h6>
                </div>
                <div class="row clearfix">
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icons8-mail.svg"
                                        alt=""></span></div>
                            <h4>We See, we let you knows</h4>
                            <div class="text">Stay notified with Patrol Privacy. Sign up for alerts when your email is
                                found in future data breaches.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-2.svg"
                                        alt=""></span></div>
                            <h4>Find it Fast</h4>
                            <div class="text">Use Patrol Privacy's search feature to quickly check if your information
                                has been compromised.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-3.svg"
                                        alt=""></span></div>
                            <h4>Get your info when no one else can</h4>
                            <div class="text">Research made easy. Patrol Privacy's database of breaches allows for easy
                                identification of key social IDs.</div>
                        </div>
                    </div>
                    <!--Why Block-->
                    <div class="why-block col-md-3 col-xs-12">
                        <div class="inner-box">
                            <div class="icon-box"><span class="icon"><img src="./assets/landing/images/icon-4.svg"
                                        alt=""></span></div>
                            <h4>Better Suit Up</h4>
                            <div class="text">Comprehensive coverage. Patrol Privacy covers personal, corporate, and
                                organizational data to ensure you're always aware of potential breaches.</div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <!--Intro Section-->
        <section class="intro-section" id="intro-section">
            <div class="auto-container">

                <div class="row clearfix">
                    <!--Text Column-->
                    <div class="text-col col-lg-6 col-md-12 col-sm-12">
                        <div class="inner">
                            <div class="title-box">
                                <!-- <h6 class="subtitle"><span>WHAT IS ICO CRYPTO</span ></h6>
 -->
                                <h2>Take Your Personal Security to the next level.</h2>
                            </div>
                            <div class="about">
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-users"></span></div>
                                        <h5>Discover your data's fate</h5>
                                        <div class="text">Patrol Privacy helps you find out if your personal information
                                            has been compromised."</div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-globe"></span></div>
                                        <h5>Take control of your online identity</h5>
                                        <div class="text">Use Patrol Privacy to see if your data has been breached.
                                        </div>
                                    </div>
                                </div>
                                <div class="info-block">
                                    <div class="inner-box">
                                        <div class="icon-box"><span class="icon fa fa-eye"></span></div>
                                        <h5>Be aware, be informed</h5>
                                        <div class="text">Use Patrol Privacy to check if your information is part of a
                                            data breach.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Image Column-->
                    <div class="image-col col-lg-6 col-md-12 col-sm-12">
                        <div class="inner">
                            <div class="image">
                                <img src="./assets/landing/images/globe-1.jpg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <!--How It Works-->
        <section hidden class="how-it-works">
            <div class="bg-layer-right" style="background-image: url('./assets/landing/images/pattern-1.png');"></div>
            <div class="bg-layer-center" style="background-image: url('./assets/landing/images/pattern-2.png');"></div>
            <div class="bg-layer-two"></div>
            <div class="auto-container">
                <div class="title-box centered">
                    <h6 class="subtitle"><span>How it works</span></h6>
                    <h2>Features & Innovations</h2>
                </div>

                <div class="carousel-box">
                    <div class="pagers-box">
                        <div class="pager-one clearfix">
                            <a href="" class="pager-item" data-slide-index="0">
                                <div class="icon-box"><i class="fa-solid fa-shield-check"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="1">
                                <div class="icon-box"><i class="fa-solid fa-file-shield"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="2">
                                <div class="icon-box"><i class="fa-solid fa-fingerprint"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="3">
                                <div class="icon-box"><i class="fa-solid fa-circle-dollar"></i></div>
                            </a>
                            <a href="" class="pager-item" data-slide-index="4">
                                <div class="icon-box"><i class="fa-solid fa-coins"></i></div>
                            </a>
                        </div>
                    </div>
                    <div class="slider-box">
                        <div class="how-work-carousel" id="how-work-carousel">
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Assists in preventing account compromise</h5>
                                    <div class="text">• A trail-blazing database maintained securely by Patrol Privacy,
                                        a rich solution that will allow your enterprise security to go next level.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Next Generation solution to prevent compromises</h5>
                                    <div class="text">• Keeping track of Deep-Web/Dark-Web leaks in real-time.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Assists in the investigation of identity theft</h5>
                                    <div class="text">• Use the Patrol Privacy cyber intelligence dataset to detect
                                        potential identity fraud.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Low-Cost </h5>
                                    <div class="text">• We are not pricey and do not charge for the majority of our
                                        services. People should not have to pay for security to feel secure on the
                                        internet.</div>
                                </div>
                            </div>
                            <div class="slide">
                                <div class="inner-box">
                                    <h5>Private Data-base</h5>
                                    <div class="text">• Patrol Privacy is constantly obtaining new and private datasets
                                        that other services simply do not have. We are always the first to respond.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <!--Main Footer-->
        <footer class="main-footer">

            <div class="footer-bottom">
                <div class="auto-container">
                    <div class="inner">
                        <div class="copyright">© 2023 Patrol Privacy . All Rights Reserved</div>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</div>



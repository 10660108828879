import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthApi } from '../shared/app_url/auth_apis_url';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'accept': '*/*'
  }),
};

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  public IS_USR_LOGGED: boolean = false;
  public USER_EMAIL: any;
  public USER_ID: any;
  public USER_ROLE: any;
  public TOKEN_KEY: any;
  public USER_PASS: any;
  public USER_PASSWORD:any;




  constructor(private http: HttpClient, private router: Router) {
    let islogged = localStorage.getItem('USR_ISLOGD');
    this.TOKEN_KEY = localStorage.getItem('KEY');
    this.IS_USR_LOGGED = islogged == 'true' ? true : false;
    this.USER_EMAIL = atob(localStorage.getItem('USR_MID'));
    this.USER_ID = localStorage.getItem('USR_ID') ? atob(localStorage.getItem('USR_ID')) : undefined;
    this.USER_ROLE = localStorage.getItem('USR_ROL') ? atob(localStorage.getItem('USR_ROL')) : undefined;
  }

 



  setLoggedIn() {
    localStorage.setItem('USR_ISLOGD', 'true');
    this.IS_USR_LOGGED = true;
  }

  getLoggedIn() {
    return !!this.IS_USR_LOGGED;
  }

  signOut() {
    this.IS_USR_LOGGED = false;
    localStorage.removeItem('USR_ISLOGD');
    localStorage.removeItem('USR_ID');
    localStorage.removeItem('USR_MID');
    localStorage.removeItem('USR_ROL');
    localStorage.removeItem('USR_ISLOGD');
    this.router.navigate(['/ac/login']);
  }




  signIn(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.matchPwd, data, httpOptions);
  }

  sendLoginOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.sendLoginOtp, data, httpOptions);
  }

  validateLoginOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.validateLoginOtp, data, httpOptions);
  }

  signUp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.register, data, httpOptions);
  }

  getOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.resendOtp, data, httpOptions);
  }

  veryfyUserOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.verifyUserOtp, data, httpOptions);
  }

  getToken(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.authenticate, data, httpOptions);
  }

  // getToken1(data:any):Observable<any>{
  //   return this.http.post<any>(AuthApi.userAuth, data, httpOptions);
  // }


  /**USER MICROSERVICES**/

  sendPwdResetOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.sendPwdResetOtp, data, httpOptions);
  }

  validateResetPweOtp(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.validateResetPweOtp, data, httpOptions);
  }

  resetPwd(data: any): Observable<any> {
    return this.http.post<any>(AuthApi.resetPwd, data, httpOptions);
  }

  updatePwd(data: any): Observable<any> {
    return this.http.put<any>(AuthApi.updatePwd, data, httpOptions);
  }

  handleError(error) {
    let errorMessage: any = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

    }
    return throwError(errorMessage);

  }



}

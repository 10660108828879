<div class="popup-overlay" (click)="onClose()">
    <div class="popup-content">
      <img src="./assets/images/securityBg1.jpg" class="img-security-bg" />
      <button class="close-button" (click)="onClose()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
      <div class="text-inner">
        <div class="row">

          <div class="col-md-6">
            <div class="img">
              <img src="./assets/images/security.png" class="img-security"/>              
            </div>            
          </div>
          <div class="col-md-6">
            <div class="text-offer">
              <div class="box">
                <span></span>
                <!-- <h2>available <br /> <a>5 free credits till 20 march,2024</a></h2> -->
                <p class="credit-date">Avail <a class="credit-number">5</a> free credits till <br /><a class="credit-end">{{date | date:' d MMM y'}}</a></p>
              </div>
              <!-- <p class="credit-date">available <span class="credit-number">5</span> free credits till <br /><span class="credit-end">20<sup>th</sup> march, 2024</span></p> -->
              <div class="border-blur"></div>           
            </div>
            <div class="analysis-zoom">
              <a class="btn" (click)="navigation()"><span>Register Now</span></a>
            </div>            
          </div>
          
        </div>
        
        <!-- <div class="analysis-zoom">
            <a class="btn" href="#"><span>Try for free</span></a>
        </div> -->
      </div>
      
    </div>
</div>

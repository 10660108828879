import { environment } from "src/environments/environment";

const user_url = environment.user;
const credit_url = environment.credit;
const price_url = environment.price;
export class UserApi{   
    public static getUser =user_url +  "user/getUser"
    public static getAllUser =user_url +  "user/getAllUsers";
    public static getUserWithPending =user_url +  "user/getUsersWithPendingApproval";
    public static userApproval =user_url +  "user/userApproval";
    public static userDecline =user_url +  "user/userDecline";
    public static updateUser =user_url + "user/updateUser";
    public static lockedStatus =user_url +  "user/lockedOrUnlockedUser"
    public static getRepositoryAllUsers = "user/getRepositoryAllUsers"
    public static deleteUser = "/user/deleteUser"
    public static  userAdmin = "/user/userAdmin"
    public static  adminOtp  =  "/user/adminOtp"
    public static  logs  =  "/userlog"
    
    public static creditBalance =credit_url + "credit/creditBalance";
    public static checkCredit =credit_url + "credit/checkCredits";
    public static addCreditByAdmin =credit_url + "credit/addCreditByAdmin"
    public static createCreditOrder =credit_url + "credit/createCreditOrder"
    public static updateCreditOrder =credit_url + "credit/updateCreditOrder"
   

    public static getPrice =price_url + "price/get";
    public static updatePrice =price_url + "price/update";
    public static getPlans =price_url + "price/getPlans";
    public static updatePlans =price_url + "price/updatePlans";

}
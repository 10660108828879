import { Component, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ProfilerService } from 'src/app/services/profiler.service';
import { SharingDataService } from 'src/app/services/sharing-data.service';
import { ProfileSearchComponent } from '../../profile-finder/profile-search/profile-search.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
 isMiniSidebar:boolean=false;
 showProfilenav:boolean=false;
 pendingApprovalUserList:any=[];
 isAdmin:boolean=false;
  constructor(
    private userService:UserService,
    private authServices:AuthService,
    private profilerService:ProfilerService,
    private authService:AuthService,
    private router:Router,
    private dialog:MatDialog,
    public sharingService:SharingDataService) {
      this.isAdmin = this.authService.USER_ROLE == 'ADMIN'; 
      this.profilerService.isMiniSidebar.subscribe((res:any)=> this.isMiniSidebar = res);
      this.router.events.pipe(filter((event)=> event instanceof NavigationEnd))
      .subscribe(()=> this.showProfilenav = false);
     }


 ngOnInit() {
  this.userwithPendingApproval()
    //  this.initparticles();
 }
//  initparticles() {
//   let wrapper = document.querySelector('.headers_wrapper');
//   for (let i = 0; i < 100; i++) {
//     let holder = document.createElement('div');
//     let circle = document.createElement('div');
//     holder.className = "circle-container";
//     circle.className = "circle";
//     holder.appendChild(circle);
//     wrapper.appendChild(holder);
//   }
// }
 navigateToHome(){
  if(this.authServices.USER_ID){
    this.router.navigate(['/def/dashboard']);
  }else{
    this.router.navigate(['/ac/login']);
  }
 }

// toggleSidebar(){
//   let flag: Boolean
//   if(this.isMiniSidebar == false){
//     flag = true;
//   } 
//   else {
//     flag= false;
//   }
//   localStorage.setItem('toggleShow',JSON.stringify(flag))
//   this.profilerService.isMiniSidebar.next(flag);
// }
userwithPendingApproval(){
  let obj:any={};
  obj["page"] = 0;
  obj["limit"] = 10;
  obj["adminId"] = this.authService.USER_ID; 

  this.userService.getUserWithPendingApproval(obj).subscribe(
    (res:any) => {
      if(res["type"]=="success"){
      this.pendingApprovalUserList = res;
    }
    },
    (err)=> console.log(err)
  )
}
navigateTo(urls: string, filterType?: any) {
  
  if (filterType == undefined) this.router.navigate([urls]);
  this.router.navigate([urls], { queryParams: { filter: filterType } });

}
toggleSidebar(){
  if(this.isMiniSidebar == false) this.isMiniSidebar = true;
  else this.isMiniSidebar = false;
  this.profilerService.isMiniSidebar.next(this.isMiniSidebar);
}
logOut(){ 
  this.authServices.IS_USR_LOGGED = false;
  localStorage.removeItem('USR_ID');
  localStorage.removeItem('USR_MID');
  localStorage.removeItem('USR_ROL');
  localStorage.removeItem('USR_ISLOGD');
  localStorage.removeItem('KEY');
  this.authService.USER_ID = null;
  this.router.navigate(['/ac/login']);  
}

 /**OPEN SEARCH MODEL**/
 openDialog() {
  const dialogRef = this.dialog.open(ProfileSearchComponent, {
    maxWidth:"90%",
    panelClass: ["profiler_search", "custom_global_model"],
    data: { repositoryName: '', title: "Search Profile" }
  });
  dialogRef.afterClosed().subscribe(result => {
    // console.log(`Dialog result: ${result}`);
  });
  this.profilerService.showMainLoader.next(false);
}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollSpyDirective } from './../partial/dirctives/scroll-spy.directive';
import { StarRatingComponent } from './../partial/layout/star-rating/star-rating.component'
import { HeaderComponent } from '../pages/common/header/header.component';
import { SidebarComponent } from '../pages/common/sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { ClickElsewhereDirective } from '../partial/dirctives/click-elsewhere.directive';
import { MaterialModule } from './material.module';
import { SearchFilterPipe } from '../partial/pipes/search-filter.pipe';
import { PopupComponent } from './popup/popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


const sharedComponent = [
  ScrollSpyDirective,
  StarRatingComponent,
  HeaderComponent,
  SidebarComponent,
  ClickElsewhereDirective,
  SearchFilterPipe,
  PopupComponent
]

@NgModule({
  declarations: [sharedComponent],
  imports: [
    CommonModule,
    RouterModule, 
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  exports: [sharedComponent]
})
export class SharedComponentModule { }

import { environment } from "src/environments/environment";
const profiler_url = environment.profilemanager;
export class profilerApis{
 public static addKeywordToSearchList = profiler_url + "profilemanager/addKeywordToSearchList";
 public static showHistoryList = profiler_url + "profilemanager/showAllKeywordFromSearchList";
public static getAllHistoryList =profiler_url + "profilemanager/getKeywordFromSearchList";
 public static searchKeywordHistory = profiler_url + "profilemanager/searchKeywordHistory";
 public static searchHistory = profiler_url + "profilemanager/searchHistory";
 public static searchStatusHistory = profiler_url + "profilemanager/searchStatusHistory";
 public static searchStatusRepository = profiler_url + "profilemanager/searchStatusRepository";
 public static showResultOfSearchRepository = "profilemanager/showResultOfSearchRepository";

 public static deleteRepository = "profilemanager/deleteRepository";
 public static updateRepository = "profilemanager/updateRepository";
 public static uploadImage = "profilemanager/uploadImage";

 public static createBlog ="http://192.168.2.190:5010/blog";
//  public static uploadImage = "profilemanager/uploadImage";
//  public static uploadImage = "profilemanager/uploadImage";
//  public static uploadImage = "profilemanager/uploadImage";




 
}
import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProfilerService } from 'src/app/services/profiler.service';
import { SharingDataService } from 'src/app/services/sharing-data.service';


@Component({
  selector: 'app-sidebar',
  host:{'(document:click)': 'detectOutsideClicked($event)'},
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isExpaned:boolean=false;
  isAdmin:boolean=false;
  constructor(
    private elementRef: ElementRef,
    private authService:AuthService,
    private profilerService:ProfilerService,
    private shareService:SharingDataService) {
      this.isAdmin = this.authService.USER_ROLE == 'ADMIN';     
   }

  ngOnInit(): void {
    // this.initparticles();
   }
  //  initparticles() {
  //   let wrapper = document.querySelector('.headers_wrapper');
  //   for (let i = 0; i < 100; i++) {
  //     let holder = document.createElement('div');
  //     let circle = document.createElement('div');
  //     holder.className = "circle-container";
  //     circle.className = "circle";
  //     holder.appendChild(circle);
  //     wrapper.appendChild(holder);
  //   }
  // }

  detectOutsideClicked(event) {

    if(window.outerWidth > 767) return;
    const targetElement = event.target as HTMLElement;   

    if (targetElement && !findAncestor(targetElement, 'navbar-nav')  && !this.elementRef.nativeElement.contains(targetElement)) { 
      this.profilerService.isMiniSidebar.next(true);

    }   
   }

}

function findAncestor (el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls));
  return el;
}
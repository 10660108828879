import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { ProfilerService } from './services/profiler.service';
import { SharingDataService } from './services/sharing-data.service';
import { UserService } from './services/user.service';
import { AnimationOptions } from 'ngx-lottie';


@Component({
  selector: 'app-root',
  template: `
  <router-outlet *ngIf="!isLoading"></router-outlet> 
  <ng-lottie class="loaderCss" *ngIf="isLoading" containerClass="moving-box" [styles]="styles" [options]="options"></ng-lottie>
 
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  options: AnimationOptions = {
    path: '/assets/animation/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {  
    margin: '150px auto',
    width:'14%',
    height: '72%',
  };
  userRole: any;
  showLoader: boolean = false;
  isLoading: boolean= true;
  preRoute: any = "";
  constructor(
    private userServices: UserService,
    private sharingDataServices: SharingDataService,
    private profilerService: ProfilerService,
    private authServices: AuthService,
    private route: ActivatedRoute,
    private bnIdle: BnNgIdleService,
    private router: Router) {
    this.sharingDataServices.showLoader.subscribe((res: boolean) => this.showLoader = res);
    this.userRole = this.authServices.USER_ROLE;
    this.router.events.pipe(filter(e => e instanceof NavigationStart))
      .subscribe((res: any) => {
        let urlAsArr = res["url"].split('?')[0].split('/');
        if (res.navigationTrigger === 'popstate') {
          window.location.reload();
        }      

        if (!urlAsArr.includes('profile-finder') && !urlAsArr.includes('report')) {   
          setTimeout(()=>{            ;
            this.profilerService.adminConfigToSeeUserSearch.selectedUserId = this.authServices.USER_ID
          }, 500)             
        }

        if ((this.preRoute.split('/').includes('ac')) && (!urlAsArr.includes('ac'))) {
          this.getCreditBalance();
        }
        setTimeout(() => {this.preRoute = res["url"]}, 3000);

        setTimeout(() => {
          this.isLoading = false;
        }, 1000);
      })
  }
  ngOnInit() {
    this.bnIdle.startWatching(60 * 60 * 6).subscribe((isTimeout: boolean) => {
      if (isTimeout) {
        this.authServices.signOut();
        console.log("Your Session Expired due to longer Inactivity, Login Again To Continue");
      }
    })
    this.setUserId();
    this.getCreditBalance();
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe(result => {
      if (result["loginId"] && result["role"]) {
        localStorage.setItem('user_id', JSON.stringify(result));
        this.sharingDataServices.userLoginId.next(result);
      } else {
        let result = JSON.parse(localStorage.getItem('user_id'));
        if (result !== null) {
          this.sharingDataServices.userLoginId.next(result);
        }
      }
    });
  }


  getCreditBalance() {
    if (!this.authServices.USER_ID) return;
    this.userServices.creditBalance(this.authServices.USER_ID)
      .subscribe(
        (res: any) => {
          if (res["type"] == "success") {
            this.sharingDataServices.getCreditInfo.next(res);
          }
        },
        (err) => console.error(err)
      )
  }



  userId: any;
  setUserId() {
    let obj = {};
    obj["loginId"] = atob(localStorage.getItem('USR_ID')) || null;
    obj["role"] = atob(localStorage.getItem('USR_ROLE')) || null;
    this.sharingDataServices.userLoginId.next(obj);
  }



}

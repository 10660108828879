import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-dialog',
  template:`
  <h3 mat-dialog-title class="mat-dialog-title">{{title}}</h3>
<div mat-dialog-content>
    <textarea [(ngModel)]="message" class="form-control text-white"></textarea>
</div>
<div mat-dialog-actions>
  <button mat-raised-button   cdkFocusInitial color="primary" (click)="onConfirm()">Ok</button>
  <button mat-raised-button    (click)="onDismiss()"  cdkFocusInitial>Cancel</button>
</div>`,
  styles: [``]
})
export class PromptDialogComponent{
  title: string;
  message: string;

  constructor(
    public dialogRef:MatDialogRef<PromptDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:PromptDialogModel) {
      this.title = data.title;
     }

  onConfirm(): void {
    let obj:any = {};
    obj["isOk"] = true;
    obj["message"]= this.message
    this.dialogRef.close(obj);
  }

  onDismiss(): void {
    let obj:any = {};
    obj["isOk"] = false;
    this.dialogRef.close(obj);
  }

}


export class PromptDialogModel {
  constructor(public title: string) {
  }

}

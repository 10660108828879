import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { profileInfo } from '../shared/model-scheema';
import { AuthService } from './auth.service';
import { SharingDataService } from './sharing-data.service';

@Injectable({
  providedIn: 'root'
})
export class ProfilerService {
  getHeaderHeight: any = new BehaviorSubject(100);
  isResetNewsSourceFrom: any = new BehaviorSubject(false);
  resetLocationForm: any = new BehaviorSubject(false);  
  imgProcessingOption: any = new BehaviorSubject(false);
  private reloadKeywordHistory = new Subject<any>();
  private addResultManually = new Subject<any>();
  public finalPublishedData: any = {};
  public showValidatedLocalData:boolean = false;
  public isChangeProfilePic:boolean=false;
  public repocredential: any = {}
  public isOnlyOmnicentReport:boolean=false;
  public isCollpaseLeftPanel = new BehaviorSubject<boolean>(false);
  public userLists = new BehaviorSubject<any[]>([]);
  public showOtherUserSearch = new BehaviorSubject<boolean>(false)

  public allSource = [];
  public validatedRepositoryData: any = [];
  public keywordType = new BehaviorSubject<any>('name');
  public sourceType = new BehaviorSubject<any>('twitter');
  public allHistoryData = new BehaviorSubject<any>([]);
  public sendEvents = new BehaviorSubject<any>(null); 
  public showMainLoader = new BehaviorSubject<any>(false);
  public sourceDataCount: any = {};
  public isdataSet: any = {};   
  public isMiniSidebar = new BehaviorSubject<any>(false);
  adminConfigToSeeUserSearch:any={
    activeSearchModule:false,
    selectedUserId:this.authService.USER_ID,
    adminId:this.authService.USER_ID,
    viewdAnotherSearch:false
  }

  constructor(
    private sharingService:SharingDataService,
    private toastr: ToastrService,
    private authService:AuthService,
    public router: Router, ) { 
      this.allHistoryData.subscribe(res => {
        if (res) {
          if (res["phoneResultList"]?.length) {
            localStorage.setItem('social_contact_details', JSON.stringify(res["phoneResultList"]))
          }
          for (let dataset in res) {
            let searchType = dataset.substring(0, dataset.length - 10);
            res[dataset].length ? this.isdataSet[searchType] = true : this.isdataSet[searchType] = false;
          }
        }
      })
    }

    raiseReloadKeywordHistory(obj: {}) {   
      this.reloadKeywordHistory.next(obj);
    }
    getReloadKeywordHistory(): Observable<any> {
      return this.reloadKeywordHistory.asObservable();
    }

    raiseAddResultManually(obj: {}) {
      console.log(obj);
      this.addResultManually.next(obj);
    }
    
    getResultManually(): Observable<any> {
      return this.addResultManually.asObservable();
    }


  viewProfileFromHistory: any = new BehaviorSubject(false);
  viewedSearchItemInfo: any = new BehaviorSubject({});
  refreshHistory: any = new BehaviorSubject(false);

  public selectedHistoryList: any = [];
  public profilePerson: any = "";
  public isValidatePublishBtn = new BehaviorSubject<boolean>(false); // to be remove

  profileObj: profileInfo = {
    profile_pic: "",
    fistName: "",
    lastName: "",
    orgName: "",
    mobile: "",
    emailid: "",
    category: "",
    gender: "",
    dob: "",
    rating: 1,
    tagList: [],
    location: "",
    description: "",
    bookmark: "no",
    address: "",
    comment: [] = []
  }

  bookmark() {
    if (this.profileObj.bookmark == 'no') {
      this.profileObj.bookmark = 'yes'
    } else {
      this.profileObj.bookmark = 'no'
    }
  }

  public imageObj = {
    url: "./assets/images/default.jpg"
  }

  
  public profilePic = new BehaviorSubject(this.imageObj.url);
  public showProfilePreview: boolean = false // false => hide profile info;
  public showProfiler: boolean = false;
  public profilePhoto: any = '';
  public resetSearchDataSet() {
    // this.resetProfileView();
    this.resetDataSet();
    //this.showHideProfilePreview.next(false);
    this.showProfilePreview = false;
  }
  loginUser: any;
  userRole: any;

  public published_data: any = {}
  // public publishProfile() {
  //   this.sharingService.userLoginId.subscribe(res => {
  //     this.loginUser = res["loginId"]
  //     this.userRole = res["role"];
  //   })    
  //   let obj = {}
  //   obj["repositoryName"] = this.repocredential["repositoryName"]
  //   obj["repositoryId"] = this.repocredential["repositoryId"]
  //   obj["searchKeyword"] = this.repocredential["repositoryName"]
  //   obj["searchKeywordId"] = this.repocredential["repositoryId"]
  //   obj["firstName"] = this.profileObj.fistName;
  //   obj["lastName"] = this.profileObj.lastName;
  //   obj["orgName"] = this.profileObj.orgName;
  //   obj["gender"] = this.profileObj.gender;
  //   obj["dob"] = this.profileObj.dob;
  //   obj["address"] = this.profileObj.location;
  //   obj["photo"] = this.profilePhoto;
  //   obj["description"] = this.profileObj.description;
  //   obj["phoneNumber"] = this.profileObj.mobile;
  //   obj["emailId"] = this.profileObj.emailid;
  //   obj["classification"] = this.profileObj.category;
  //   obj["threatScore"] = this.profileObj.rating;
  //   obj["bookmark"] = this.profileObj.bookmark;
  //   obj["viewCount"] = 1;
  //   obj["isPublished"] = "yes";
  //   obj["tags"] = this.profileObj.tagList;
  //   obj["comment"] = this.profileObj.comment;
  //   obj["userLoginId"] = this.loginUser;
  //   obj["userRole"] = this.userRole;
  //   let dataSet: {}[];    
  //   dataSet = JSON.parse(JSON.stringify(this.allRepoDataInSingleArr));
  //   dataSet.forEach(d => {
  //     if (d["addedtocollection"]) delete d["addedtocollection"]
  //   })   
  //   let fobj = {};
  //   fobj["basic_info"] = obj;
  //   fobj["social_media_account"] = dataSet;
  //   fobj["id"] = this.repocredential["repositoryId"]
  //   this.published_data = fobj;
  //   console.log(fobj);
  //   this.profileDbService.publishProfile(fobj).subscribe(
  //     res => {
  //       console.log(res);
  //       if (res['msg'] === 'success') {
  //         // this.raiseReloadKeywordHistory({ "type": "reload" });
  //       } else {
  //         this.toastr.error('Something went wrong!', 'Error');
  //       }
  //     },
  //     err => console.log(err)
  //   )
  // }


  //Flags to show various message after profile search
  registerdMsg: boolean = false;
  nodataMsg: boolean = false;
  searchResultFoundMsg: boolean = true;   //   true==hide,false=show
  underProcessingMsg: boolean = false;
  noSearchMsg: boolean = true; //true        // show default msg 
  setFlag(msg) {
    this.registerdMsg = false;
    this.nodataMsg = false;
    this.searchResultFoundMsg = true;
    this.underProcessingMsg = false;
    this.noSearchMsg = false;
    switch (msg) {
      case "registerdMsg":
        this.registerdMsg = true;
        break;
      case "nodataMsg":
        this.nodataMsg = true;
        break;
      case "searchResultFoundMsg":
        this.searchResultFoundMsg = false;
        break;
      case "underProcessingMsg":
        this.underProcessingMsg = true;
        break;
      case "noSearchMsg":
        this.noSearchMsg = true;
        break;
    }
  }


  // repositoryBasicDetails(basicInfo) {    
  //   if (basicInfo) {     
  //     if (basicInfo["photo"] !== '') {
  //       this.imageObj.url = basicInfo["photo"];
  //       this.profilePic.next(basicInfo["photo"]);
  //       this.profilePhoto = basicInfo["photo"];
  //     }else{
  //       if (!this.isChangeProfilePic) this.profilePic.next('');
  //     }
  //     this.profileObj.fistName = basicInfo["firstName"];
  //     this.profileObj.lastName = basicInfo["lastName"];
  //     this.profileObj.orgName = basicInfo["orgName"];
  //     this.profileObj.gender = basicInfo["gender"];
  //     this.profileObj.category = basicInfo["classification"];
  //     this.profileObj.rating = basicInfo["threatScore"];
  //     this.profileObj.dob = basicInfo["dob"];
  //     this.profileObj.emailid = basicInfo["emailId"];
  //     this.profileObj.mobile = basicInfo["phoneNumber"];
  //     this.profileObj.location = basicInfo["address"];
  //     this.profileObj.bookmark = basicInfo["bookmark"];
  //     this.profileObj.description = basicInfo["description"];
  //     this.profileObj.tagList = basicInfo["tags"];     
  //     if (basicInfo["comment"]) {
  //       this.profileObj.comment = basicInfo["comment"];
  //     }
  //     else {
  //       this.profileObj.comment = [];
  //     }
  //   }
  // }


  /**COMMON AND GLOBAL DATA**/  
  public avatarCat: any = []
  public proxyList: any = [];
  public category: any = []
  public countryList = [];
  public categoryList = [];
  bindMaster() {    
    // this.profileDbService.selectAllMaster({}).subscribe(
    //   (res:any) => {
    //     this.avatarCat = res["avatarCategory"];
    //     this.proxyList = res["proxy"];
    //     this.categoryList = res["category"];
    //     this.countryList = res["country"]; 
    //     let cat_arr = res["category"];
    //     if (cat_arr?.length){
    //     cat_arr.forEach(element => {
    //        this.categoryList.push(element["category"]);
    //      });
    //    }     
    //   },
    //   (err:any) =>  console.log(err))
  }


  

  public twitterDataSet = [];
  public facebookDataSet = [];
  public instagramDataSet = [];
  public youtubeDataSet = [];
  public tumblrDataSet = [];
  public truecallerDataSet = [];
  public whatsappDataSet = [];
  public linkedinDataSet = [];
  public skypeDataSet = [];
  public dailymotionDataSet = [];
  public googlewebDataSet = [];
  public snapchatDataSet = [];
  public bloggerDataSet = [];
  public weiboDataSet = [];
  public flickrDataSet = [];
  public telegramDataSet = [];
  public redditDataSet = [];
  public viberDataSet = [];
  public zaloDataSet = [];
  public lineDataSet = [];
  public imoDataSet = [];
  public threemaDataSet = [];
  public slackDataSet = [];
  public wechatDataSet = [];
  public vkDataSet = [];
  public omnicentDataSet = [];
  public federatedDataSet = []
  public signalDataSet = [];
  public lookupDataSet = [];

  // public searchKeyword: string;
  // public searchKeywordId: string;


 

  
  public resetDataSet() {
    this.twitterDataSet = [];
    this.facebookDataSet = [];
    this.instagramDataSet = [];
    this.youtubeDataSet = [];
    this.tumblrDataSet = [];
    this.truecallerDataSet = [];
    this.whatsappDataSet = [];
    this.linkedinDataSet = [];
    this.skypeDataSet = [];
    this.dailymotionDataSet = [];
    this.googlewebDataSet = [];
    this.snapchatDataSet = [];
    this.bloggerDataSet = [];
    this.weiboDataSet = [];
    this.flickrDataSet = [];
    this.redditDataSet = [];
    this.telegramDataSet = [];
    this.viberDataSet = [];
    this.zaloDataSet = [];
    this.lineDataSet = [];
    this.imoDataSet = [];
    this.threemaDataSet = [];
    this.slackDataSet = [];
    this.wechatDataSet = [];
    this.vkDataSet = [];
    this.omnicentDataSet = []
    this.federatedDataSet = [];
    this.signalDataSet = [];
    this.lookupDataSet = [];
    // this.searchKeyword = "";
    // this.searchKeywordId = "";
  }


  
  public mergeDataSet() {
    let mergegedDataSet: {}[] = [];
    mergegedDataSet = mergegedDataSet.concat(this.twitterDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.facebookDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.instagramDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.youtubeDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.linkedinDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.tumblrDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.dailymotionDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.whatsappDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.truecallerDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.skypeDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.googlewebDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.bloggerDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.snapchatDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.weiboDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.flickrDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.redditDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.telegramDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.viberDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.zaloDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.lineDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.imoDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.threemaDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.slackDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.wechatDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.vkDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.omnicentDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.signalDataSet);
    mergegedDataSet = mergegedDataSet.concat(this.lookupDataSet);

    return mergegedDataSet;
  }


  
  // public categoryList = ["crime", "general", "political", "radical", "terrorism"];
  public socailMediaList = [
    { "key": "Facebook", "value": "facebook" },
    { "key": "Twitter", "value": "twitter" },
    { "key": "Instagram", "value": "instagram" },
    { "key": "Youtube", "value": "youtube" },
    { "key": "Tumblr", "value": "tumblr" },
    { "key": "Linkedin", "value": "linkedin" },
    { "key": "Dailymotion", "value": "dailymotion" },
    { "key": "Web", "value": "googleweb" },
    { "key": "Whatsapp", "value": "whatsapp" },
    { "key": "Truecaller", "value": "truecaller" },
    { "key": "Skype", "value": "skype" },
    { "key": "Blogger", "value": "blogger" },
    { "key": "Snapchat", "value": "snapchat" },
    { "key": "Weibo", "value": "weibo" },
    { "key": "Flickr", "value": "flickr" },
    { "key": "Reddit", "value": "reddit" },
    { "key": "Omnicent", "value": "omnicent" }
  ];

  

  public allSourceType = ["twitter", "facebook", "youtube", "instagram", "linkedin", "tumblr", "dailymotion", "whatsapp", "truecaller", "skype", "googleweb", "blogger", "snapchat", "weibo", "flickr", "reddit", "telegram", "viber", "zalo", "line", "imo", "threema", "slack", "wechat", "vk", "omnicent", "federated", "signal"]


  /**CHECK IF DATA IS VALIDATED FOR COMPLETE REPOSITORY DATA**/
  allRepoDataInSingleArr: any = [];
  publishedValidatedAll(isPhone, repodata: any) {
    if (repodata) {
      this.validatedRepositoryData.length = 0
      if (repodata["nameResultList"].length) {
        this.allRepoDataInSingleArr.push(...repodata["nameResultList"]);
        let n = repodata["nameResultList"].filter(x => x.validated == 'yes');
        this.validatedRepositoryData.push(...n);
      }

      if (repodata["phoneResultList"].length) {
        let onlyFedrated = JSON.parse(JSON.stringify(repodata["phoneResultList"]
          .filter(d => d.sourceType === 'federated')))
        this.allRepoDataInSingleArr.push(...onlyFedrated);
        let p = repodata["phoneResultList"].filter(x => x.validated == 'yes');
        this.validatedRepositoryData.push(...p);
      }

      if (isPhone === 'all') {
        if (repodata["phoneResultList"].length) {
          let onlycraw
          this.allRepoDataInSingleArr.push(...repodata["phoneResultList"]);
          let p = repodata["phoneResultList"].filter(x => x.validated == 'yes');
          this.validatedRepositoryData.push(...p);
        }
      }
      if (repodata["emailResultList"].length) {
        this.allRepoDataInSingleArr.push(...repodata["emailResultList"]);
        let e = repodata["emailResultList"].filter(x => x.validated == 'yes');
        this.validatedRepositoryData.push(...e);
      }
      if (repodata["imageResultList"].length) {
        this.allRepoDataInSingleArr.push(...repodata["imageResultList"]);
        let i = repodata["imageResultList"].filter(x => x.validated == 'yes');
        this.validatedRepositoryData.push(...i);
      }
      this.validatedRepositoryData.forEach(element => {
        element['addedtocollection'] = true;
      });
    }
  }

 

   /**UPDATE DATA IN DATA SET**/
   updateInDataSet(data: [], sourceName) {
    if (data.length > 0) {
      data.forEach(element => {
        if (element["sourceType"] == sourceName) {
          switch (element["sourceType"]) {
            case "twitter":
              this.twitterDataSet.push(element);
              break;
            case "facebook":
              this.facebookDataSet.push(element);
              break;
            case "youtube":
              this.youtubeDataSet.push(element);
              break;
            case "instagram":
              this.instagramDataSet.push(element);
              break;
            case "tumblr":
              this.tumblrDataSet.push(element);
              break;
            case "truecaller":
              this.truecallerDataSet.push(element);
              break;
            case "whatsapp":
              this.whatsappDataSet.push(element);
              break;
            case "dailymotion":
              this.dailymotionDataSet.push(element);
              break;
            case "linkedin":
              this.linkedinDataSet.push(element);
              break;
            case "googleweb":
              this.googlewebDataSet.push(element);
              break;
            case "skype":
              this.skypeDataSet.push(element);
              break;
            case "snapchat":
              this.snapchatDataSet.push(element);
              break;
            case "blogger":
              this.bloggerDataSet.push(element);
              break;
            case "weibo":
              this.weiboDataSet.push(element);
              break;
            case "flickr":
              this.flickrDataSet.push(element);
              break;
            case "reddit":
              this.redditDataSet.push(element);
              break;
            case "telegram":
              this.telegramDataSet.push(element);
              break;
            case "viber":
              this.viberDataSet.push(element);
              break;
            case "zalo":
              this.zaloDataSet.push(element);
              break;
            case "line":
              this.lineDataSet.push(element);
              break;
            case "imo":
              this.imoDataSet.push(element);
              break;
            case "threema":
              this.threemaDataSet.push(element);
              break;
            case "slack":
              this.slackDataSet.push(element);
              break;
            case "wechat":
              this.wechatDataSet.push(element);
              break;
            case "vk":
              this.vkDataSet.push(element);
              break;
            case "omnicent":
              this.omnicentDataSet.push(element);
              break;
            case "signal":
              this.signalDataSet.push(element);
              break;
              case "lookup":
                this.lookupDataSet.push(element);
                break;
            default:
              break;
          }
        }
      });
    }
  }



   /**SORT BY COUNT**/
   sortobjectbycount(objwithcount) {
    var sortable = [];
    for (var source in objwithcount) {
      sortable.push([source, objwithcount[source]]);
    }
    sortable.sort(function (a, b) {
      return b[1] - a[1];
    });
    var sortedobject = {}
    sortable.forEach(function (item) {
      sortedobject[item[0]] = item[1]
    })
    return sortedobject;
  }

  
public getAllDataSet(){
  return [
  "twitterDataSet",
  "facebookDataSet",
  "instagramDataSet",
  "youtubeDataSet",
  "tumblrDataSet",
  "truecallerDataSet",
  "whatsappDataSet",
  "linkedinDataSet",
  "skypeDataSet",
  "dailymotionDataSet",
  "googlewebDataSet",
  "snapchatDataSet",
  "bloggerDataSet",
  "weiboDataSet",
  "flickrDataSet",
  "redditDataSet",
  "telegramDataSet",
  "viberDataSet",
  "zaloDataSet",
  "lineDataSet",
  "imoDataSet",
  "threemaDataSet",
  "slackDataSet",
  "wechatDataSet",
  "vkDataSet",
  "omnicentDataSet",
  "federatedDataSet",
  "signalDataSet",
  "lookupDataSet"
    ]
}

 /**DICTIONARY WORDS FOR VALIDATED FIELDS IN PROFILE FINDER**/
  dictionary: any = ["image-Image(s)", "name-Name", "classification-Classification", "gender-Gender", "threatLevel-Threat Level", "relatedPersons-Related Persons", "fatherName-Father's Name", "sisterName-Sister's Name", "relatedOrganizations-Related Organization(s)", "daughterName-Daughter's Name", "description-Description", "relatedImage-Related Image(s)", "Related Location(s)", "birthPlace-Place of Birth", "nationalID-National ID", "deathPlace-Place of Death", "deathDate-Date of Death", "alias-Alias", "familyConnections-Connections of Family", "passportNumber-Passport Number", "wifeName-Wife's Name", "husbandName-husband's Name", "motherName-Mother's Name", "brotherName-Brother's Name's", "birthDate-Date of Birth", "sonName-Son's Name", "drivingLicense-Driving License", "comment-comment", "workExperience-Work Experience", "organizations-Organization(s)", "education-Education(s)", "designation-Designation(s)", "placeVisit-Visited Place", "location-Location(s)", "contactInformation-Contact Information", "telephoneNumber-Telephone Number(s)", "facebookID-Facebook IDs", "bankAccountNumber-BankAccount Number(s)", "address-address", "emailID-Email Id(s)", "SocialMediaHead-Social Media Head(s)", "socialMediaHandles-Social Media Handle(s)", "publications-Publication(s)", "otherInfo-Other Info"]


}

<h2 class="model_title custom-color" mat-dialog-title>{{dialogTitle}}</h2>
<form [formGroup]="searchform" autocomplete="off" (ngSubmit)="search(searchform.value)" class="searchform">
  <mat-dialog-content class="mat-typography customScrollbar ">
    <div class="search_form">
      <div class="form-group" [class.mb-4]="searchform.get('repositoryName').valid">
        <mat-form-field class="w-100" >
          <mat-label class="text-white">Repository name</mat-label>
          <input (keypress)="isSpace($event)" matInput type="text"
            formControlName="repositoryName">
          <mat-hint class="text-danger" *ngIf="(searchFormControl.repositoryName.touched  ) && searchFormControl.repositoryName.errors?.required">
            Repository Name is requried!
            <!-- <i class="fa fa-info-circle" aria-hidden="true"></i> &nbsp; -->
             
            <!-- Please complete atleast one of the below field (from "mobile", "email") to proceed further. -->
          </mat-hint>
        </mat-form-field>
      </div>



      <div [class.mobile_number_icon]="multiNoSearch==false" class="form-group multioption">
        <mat-form-field class="w-100" >
          <mat-label class="text-white">Mobile number to search</mat-label>
          <ngx-mat-intl-tel-input *ngIf="!multiNoSearch" [preferredCountries]="['in']" [enablePlaceholder]="false"
            [enableSearch]="true" #mobileno name="searchKeywordPhone" formControlName="searchKeywordPhone"
            (countryChanged)="countryChange($event)"
            (keypress)="numberOnly($event, mobileno.value)"></ngx-mat-intl-tel-input>
          <textarea *ngIf="multiNoSearch" matInput rows="4" formControlName="searchKeywordPhone"></textarea>
          <mat-hint  class="text-danger" *ngIf="multiNoSearch ">Enter one number per line in international format. Ex: +91-9876543210 Make sure enough credits are present.</mat-hint>
          <div class="multiple_check">
            <mat-checkbox (change)="multiMode($event, 'mobile')" [checked]= "multiNoSearch" color="primary">Multi numbers</mat-checkbox>
          </div>
        </mat-form-field>
        <!-- <mat-error class="text-white" *ngIf="searchform.get('searchKeywordPhone').touched && searchform.get('searchKeywordPhone').invalid">
          Please enter valid phone number.
        </mat-error> -->
      </div>


      <div class="form-group multioption">
        <mat-form-field class="w-100">
          <mat-label  class="text-white">Email ID to search </mat-label>
          <input *ngIf="!multiEmailSearch" type="email" #emailid formControlName="searchKeywordEmail"
            (keypress)="isSpace($event)" matInput />
          <textarea *ngIf="multiEmailSearch" matInput rows="4" formControlName="searchKeywordEmail"></textarea>
          <mat-hint class="text-danger" *ngIf="multiEmailSearch">multiple email should be per line.
          </mat-hint>

          <div class="multiple_check">
            <mat-checkbox  class="text-white" (change)="multiMode($event, 'email')" color="primary" [checked]= "multiEmailSearch">Multi Email</mat-checkbox>
          </div>
        </mat-form-field>
        <mat-error  class="text-white" *ngIf="searchform.get('searchKeywordEmail').touched && searchform.get('searchKeywordEmail').invalid">
          Please enter valid email address.
        </mat-error>
      </div>

      <div class="form-group multioption">
        <mat-form-field class="w-100" >
          <mat-label class="text-white">Social Media ID to search </mat-label>
          <input *ngIf="!multiSocialMediaSearch" type="text" formControlName="searchKeywordSocialMediaId"
            (keypress)="isSpace($event)" matInput />
          <textarea *ngIf="multiSocialMediaSearch" matInput rows="4" formControlName="searchKeywordSocialMediaId"></textarea>
          <mat-hint class="text-danger" *ngIf="multiSocialMediaSearch">multiple social media should be per line.
          </mat-hint>

          <div class="multiple_check">
            <mat-checkbox class="text-white" (change)="multiMode($event, 'socialmedia')" color="primary">Multi Social Media ID</mat-checkbox>
          </div>
        </mat-form-field>
        <mat-error  class="text-danger"*ngIf="searchform.get('searchKeywordSocialMediaId').touched && searchform.get('searchKeywordSocialMediaId').invalid">
          Please enter valid social media id.
        </mat-error>
      </div>

      <div class="form-group multioption">
        <mat-form-field class="w-100" >
          <mat-label  class="text-white">National ID to search </mat-label>
          <input *ngIf="!multiNationIdSearch" type="text" formControlName="searchKeywordNationalId"
            (keypress)="isSpace($event)" matInput />
          <textarea *ngIf="multiNationIdSearch" matInput rows="4" formControlName="searchKeywordNationalId"></textarea>
          <mat-hint class="text-danger" *ngIf="multiNationIdSearch">multiple national id should be per line.
          </mat-hint>
          <div class="multiple_check">
            <mat-checkbox class="text-white" (change)="multiMode($event, 'nationalid')" color="primary">Multi National ID</mat-checkbox>
          </div>
        </mat-form-field>
        <mat-error class="text-white" *ngIf="searchform.get('searchKeywordNationalId').touched && searchform.get('searchKeywordNationalId').invalid">
          Please enter valid national id address.
        </mat-error>
      </div>

      <!-- <div class="form-group multioption" >
        <mat-form-field class="w-100" >
          <mat-label class="text-white">UPI Address to search </mat-label>
          <input *ngIf="!multiUpiAddressSearch" type="text" id="searchKeywordIpAddress"  formControlName="searchKeywordUpiId"
            (keypress)="isSpace($event)" matInput />
          <textarea id="searchKeywordIpAddress" *ngIf="multiUpiAddressSearch" matInput rows="4" formControlName="searchKeywordUpiId" ></textarea>
          <mat-hint class="text-danger" *ngIf="multiUpiAddressSearch">multiple UPI address should be per line.
          </mat-hint>
          <div class="multiple_check">
            <mat-checkbox (change)="multiMode($event, 'ip')" color="primary">Multi UPI Address</mat-checkbox>
          </div>
        </mat-form-field>
        <mat-error class="text-white" *ngIf="searchform.get('searchKeywordIpAddress').touched && searchform.get('searchKeywordIpAddress').invalid">
          Please enter valid UPI address.
        </mat-error>
      </div> -->


      <!-- <div class="form-group mobile_number_icon">
        <div class="row">
          <div class="col-md-8">
            <mat-form-field class="w-100">
              <mat-label>Mobile number to search</mat-label>
              <ngx-mat-intl-tel-input [preferredCountries]="['in']" [enablePlaceholder]="false" [enableSearch]="true"
                #mobileno name="searchKeywordPhone" formControlName="searchKeywordPhone"
                (countryChanged)="countryChange($event)"
                (keypress)="numberOnly($event, mobileno.value)"></ngx-mat-intl-tel-input>
              <button tabindex="-1" type="button" title="Add more phone numbers"
                   (click)="addToList(mobileno, 'mobile')" mat-button matSuffix mat-icon-button>
                   <mat-icon><i class="la la-plus-square"></i></mat-icon>
                 </button>
                 <mat-hint class="itemLists">
                   <ng-container *ngFor="let mobile of mobileLists; let i=index">
                     <span>{{mobile}} <i (click)="removeItem(mobileLists,i)" class="la la-close"></i></span>
                   </ng-container>
                 </mat-hint>
            </mat-form-field>
            <mat-error
               *ngIf="searchform.get('searchKeywordPhone').touched && searchform.get('searchKeywordPhone').invalid">
               Please enter valid phone number.
               </mat-error>
          </div>
          <div class="col-md-4 multiple_check">
            <mat-checkbox (change)="multiMode($event, 'mobile')" color="primary">Multiplse numbers</mat-checkbox>
          </div>
        </div>
      </div>
       -->


      <!-- <div class="form-group">
        <mat-form-field class="w-100">
          <mat-label>Email ID to search </mat-label>
          <input type="email" #emailid formControlName="searchKeywordEmail" (keypress)="isSpace($event)" matInput />
          <button type="button" title="Add more email" (click)="addToList(emailid, 'email')" mat-button matSuffix
            mat-icon-button>
            <mat-icon><i class="la la-plus-square"></i></mat-icon>
          </button>
          <mat-hint class="itemLists">
            <ng-container *ngFor="let email of emailLists; let i=index">
              <span>{{email}} <i (click)="removeItem(emailLists,i)" class="la la-close"></i></span>
            </ng-container>
          </mat-hint>
        </mat-form-field>
        <mat-error *ngIf="searchform.get('searchKeywordEmail').touched && searchform.get('searchKeywordEmail').invalid">
          Please enter valid email address.
        </mat-error>
      </div> -->

      <!-- <div  class="form-group inputTypeFile">
        <div class="row">
          <div class="col">
            <mat-form-field class="w-100 choose_image">
              <mat-label>Upload image to search</mat-label>
              <ngx-mat-file-input formControlName="searchKeywordImage" [multiple]="true" accept=".png,.jpg,.jpeg"
                (change)="preview($event)">
                <mat-icon ngxMatFileInputIcon><i class="fa fa-camera"></i>
                </mat-icon>
              </ngx-mat-file-input>
              <i *ngIf="imageUploading" class="kt-spinner kt-spinner--sm kt-spinner--dark"></i>
            </mat-form-field>
          </div>
          <div [hidden]="!uploadedImagePath.length" class="col-md-2 imagelist">
            <ng-container *ngFor="let imgUrl of uploadedImagePath; let i=index">
              <span><img [src]="imgUrl" [alt]="" onerror="this.src='./assets/images/default.jpg'" />
                <i (click)="uploadedImagePath.splice(i,1)" class="la la-close"></i>
              </span>
            </ng-container>
          </div>
        </div>
      </div> -->


      <!-- <div  class="row">
        <div class="col-md-5" *ngIf="selectedTab==='name'">
          <div class="form-group">
            <mat-form-field class="w-100">
              <mat-label>Supporting keyword</mat-label>
              <input type="text" formControlName="supportingKeyword" (keypress)="isSpace($event)" matInput value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-3 refineSearch pl-0">
          <mat-checkbox color="primary">Refine Searches </mat-checkbox>
        </div>
        <div class="col-md-4" [class.col-md-12]="selectedTab !=='name'">
          <div class="form-group">
            <app-chips [inTags]="selectedLists" (TagsInput)="tagsvalue=$event"></app-chips>
          </div>
        </div>
      </div> -->

      <!-- <div class="form-group">
        <mat-form-field class="w-100" >
          <mat-label>Description</mat-label>
          <input type="text" (keypress)="isSpace($event)" formControlName="description" matInput>
        </mat-form-field>
      </div> -->
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="form-group">
    <button type="submit" [disabled]="searchform?.invalid || (searchform.get('searchKeywordPhone').value === '' && searchform.get('searchKeywordEmail').value === '' && searchform.get('searchKeywordSocialMediaId').value === '' && searchform.get('searchKeywordNationalId').value === '' && searchform.get('searchKeywordIpAddress').value === '' && searchform.get('searchKeywordUpiId').value === '') " mat-raised-button color="primary"
      class="mr-2">{{this.repositoryId? 'Update': 'Search'}}</button>
    <button #closeBtn mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>Cancel</button>
  </div>
</form>

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
   date = new Date();
  validDate:any;
  constructor(public dialogBox: MatDialogRef<PopupComponent>,
    public router:Router,
    ) { }
  ngOnInit(): void {
   this.date.setDate(this.date.getDate() + 19);
  }
  navigation(){
    this.router.navigate(['/ac/register']);
  }
  onClose(): void {
    this.dialogBox.close();
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserApi } from '../shared/app_url/user_apis_urls';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept':'*/*',
    "Access-Control-Allow-Origin": "*"

  }),
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

   /**CREDIT MICROSERVICES**/
   creditBalance(userIds:any):Observable<any>{
    return this.http.get<any>(`${UserApi.creditBalance}/${userIds}`, httpOptions);
   }

   checkCredit(emailIds:any):Observable<any>{
      return this.http.get<any>(UserApi.checkCredit, httpOptions);
   }

   addCreditByAdmin(data:any):Observable<any>{
      return this.http.post<any>(UserApi.addCreditByAdmin, data, httpOptions)
   }

   createCreditOrder(data:any):Observable<any>{
    return this.http.post<any>(UserApi.createCreditOrder, data, httpOptions)
   }

   updateCreditOrder(data:any):Observable<any>{
    return this.http.put<any>(UserApi.updateCreditOrder, data, httpOptions)
 }


 getPlans():Observable<any>{   
  return this.http.get<any>(UserApi.getPlans);
}






/**USER DETAILS**/
   getUser(userIds:any):Observable<any>{  
    return this.http.get<any>(UserApi.getUser+'/'+userIds, httpOptions);
  }

  getAllUserForRepository(ids:{}): Observable<{}> {
    return this.http.get<{}>(UserApi.getRepositoryAllUsers+`?adminId=${ids}`);
  }
  

  getAllUser(data:any):Observable<any>{
    let searchParams = `?page=${data.page}&limit=${data.limit}&adminId=${data.adminId}&search=${data.search}&lockedStatus=${data.lockedStatus}`
    return this.http.get<any>(UserApi.getAllUser+searchParams,  httpOptions);
  }

  getUserWithPendingApproval(data:any):Observable<any>{
    let queryParams = `?adminId=${data.adminId}&limit=${data.limit}&page=${data.page}`
    return this.http.get<any>(UserApi.getUserWithPending + queryParams, httpOptions);
  }

  /**ADMIN UPDATIONS**/
  userApproval(data:any):Observable<any>{
    return this.http.post<any>(UserApi.userApproval, data, httpOptions);
  }
  userReject(data:any):Observable<any>{
    return this.http.post<any>(UserApi.userDecline, data, httpOptions);
  }
  userAdmin(data:any):Observable<any>{
    return this.http.post<any>(UserApi.userAdmin, data, httpOptions);
  }
  adminOtp(data:any):Observable<any>{
    return this.http.post<any>(UserApi.adminOtp, data, httpOptions);
  }

  updateUser(data:any):Observable<any>{
    return this.http.put<any>(UserApi.updateUser, data, httpOptions);
  }

  changeLockStatus(data:any):Observable<any>{
    return this.http.put<any>(UserApi.lockedStatus, data, httpOptions);
}

getAdvanceLog(ids:{}):Observable<any>{
  return this.http.get(UserApi.logs+`?userId=${ids}`
  )
}
/**PRICE MICROSERVICES**/

getPrice():Observable<any>{
  return this.http.get<any>(UserApi.getPrice, httpOptions);
}

updatePrice(data:any):Observable<any>{
  return this.http.post<any>(UserApi.updatePrice, data, httpOptions);
}
deleteUser(req:any):Observable<any>{
  return this.http.post<any>(UserApi.deleteUser,req,httpOptions)
}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchfilter'
})

export class SearchFilterPipe implements PipeTransform {
  transform(data: any[], searchTerms:string, searchby:any[]) { 
    if(!searchTerms || !searchTerms.length) return data; 
    if(!data) return [];
    if(!searchby && !searchby?.length) return data;    
    return data.filter((search)=>{
      let isfound:boolean=false;
      for(let i=0; i<searchby.length; i++){
        if(search[searchby[i]]?.toLowerCase().includes(searchTerms?.toLowerCase())){
          isfound=true;
          break;
        }
      }
      return isfound;
    })
  }
  // transform(value:any, searchTerm:any): any {
  //   if(!value) return;
  //   if(value.length === 0){
  //     return value;
  //   }
  //   return value.filter((search)=>{
  //     let isfound:boolean = false;
  //     if (search.name?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1){
  //       isfound = true;
  //     }
  //     if (search.screenName?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1) {
  //       isfound = true;
  //     }
  //     if (search.description?.toLowerCase().indexOf(searchTerm?.toLowerCase()) > -1) {
  //       isfound = true;
  //     }
  //     return isfound;    
  //   })
  // }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from './shared/material.module'
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common'
import { HighchartsChartModule } from 'highcharts-angular'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { MainNavComponent } from './partial/layout/main-nav/main-nav.component';
import { AlertDialogComponent } from './partial/layout/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './partial/layout/confirm-dialog/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageNotFoundComponent } from './pages/common/page-not-found/page-not-found.component';
import { PromptDialogComponent } from './partial/layout/prompt-dialog/prompt-dialog.component';
import { AuthInterceptor } from './services/auth.interceptor';
import { LandingComponent } from './pages/landing/landing.component';
import { LottieModule } from 'ngx-lottie';
import player from "lottie-web";
import { PopupComponent } from './shared/popup/popup.component';
import { SharedComponentModule } from './shared/shared-component.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';





export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    PageNotFoundComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    PromptDialogComponent,
    LandingComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HighchartsChartModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,SharedComponentModule,
    LottieModule.forRoot({ player: playerFactory }) 



 ],
  providers: [DatePipe, BnNgIdleService,
    { provide:HTTP_INTERCEPTORS, useClass:AuthInterceptor, multi:true},
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {provide: MatDialogRef,
     useValue: []
    },
    { 
      provide: MAT_DIALOG_DATA, 
      useValue: [] 
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

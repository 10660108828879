import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './pages/common/page-not-found/page-not-found.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [ 
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '', component:LandingComponent},  
  { path: 'ac', 
    loadChildren: () => import('./pages/accounts/accounts.module').then(m => m.AccountsModule) },
  { path: 'def', runGuardsAndResolvers: 'always',  
    loadChildren: () => import('./pages/default/default.module').then(m => m.DefaultModule) },
  { path: 'page-not-found', component: PageNotFoundComponent }, 
  
  { path: '**', redirectTo: 'page-not-found' }
];
// , onSameUrlNavigation: 'reload'
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true,onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler,HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {  
  constructor(
    private authService:AuthService, 
    private router:Router) {}
 intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = this.authService.TOKEN_KEY;
    let isProfilereq = request.url.split('/').includes('InnsightRestServicesNewUi');   
    let rzpurls = request.url.split('/').includes('payments');  
   if(token && !rzpurls && !isProfilereq){    
      request = request.clone({
        setHeaders: {'x-access-token': token}
      })
    }
   return next.handle(request).pipe(
     catchError((error: HttpErrorResponse) => {
       if (error instanceof HttpErrorResponse) {
         if (error.status === 401) {
          this.router.navigate(['/ac/login']);
         }
       }
       return throwError(error.error)
     })
   );
  }
}

 <h4 mat-dialog-title class="mat-dialog-title" style="color: #03a3ea;">
  {{title}}
</h4>

<div mat-dialog-content>
  <p class="mb-4 text-white">{{message}}</p>
</div>

<div mat-dialog-actions>
  <button mat-raised-button   cdkFocusInitial color="primary" (click)="onConfirm()">Yes</button>
  <button mat-raised-button    (click)="onDismiss()"  cdkFocusInitial>No</button>
  
</div>

<!-- <section class="editUser ">
  <span class="close_model_icons_btn" title="close"><i class="fa fa-times" aria-hidden="true"></i></span>
  <div class="model_header"><div class="title"><h4>{{title}}</h4></div></div>
<div mat-dialog-content class="dialog_content customScrollbar">
  <p class="mb-4">{{message}}</p>
</div>
<div class="dialog_foolter" mat-dialog-actions>
  <button mat-raised-button   cdkFocusInitial color="primary" (click)="onConfirm()">Yes</button>
  <button mat-raised-button    (click)="onDismiss()"  cdkFocusInitial>No</button>
</div>
</section>
 -->


<div class="fixed-layout ">
  <aside class="left-sidebar" style="background-image:url(../assets/images/wave-lines.png);">

    <div class="scroll-sidebar  ps ps--theme_default ps--active-y">
      <nav class="sidebar-nav active">
        <ul id="sidebarnav" class="in">
          <li routerLinkActive="active">
            <a class="waves-effect waves-dark" routerLink="/def/dashboard">
              <i class=" fas fa-home iconimg"></i>
              <!-- <img src="./assets/themes/images/icon/dashboardicon.png" alt="homepage" class="iconimg" /> -->
              <span class="hide-menu"> Dashboard </span></a>
          </li>

          <li routerLinkActive="active">
            <a class="waves-effect waves-dark" routerLink="/def/profile-finder/search-history">
              <i class=" fas fa-search  iconimg"></i>
              <!-- <img src="./assets/themes/images/icon/profile-finder.png" alt="homepage" class="iconimg" /> -->
              <span class="hide-menu"> Search Results</span>

            </a>
          </li>

          <li routerLinkActive="active" >
            <a class="waves-effect waves-dark" routerLink="/def/user/buy-credit" aria-expanded="false">
              <i class="fas fa-credit-card iconimg"></i>
              <span  matBadgeOverlap="false" class="hide-menu "> Buy Credits</span>
            </a>
          </li>
              <!--matBadge="4" *ngIf="isAdmin"<img src="./assets/themes/images/icon/buy-credit.png" alt="homepage" class="iconimg" /> -->

          <li routerLinkActive="active">
            <a class="waves-effect waves-dark" routerLink="/def/user/transaction-history">
              <!-- <img src="./assets/themes/images/icon/transaction-history.png" alt="homepage"  class="iconimg" /> -->
              <i class=" fas fa-history iconimg"></i>
              <span class="hide-menu"> Transaction History</span></a>
          </li>

          <li (clickElsewhere)="isExpaned=false" *ngIf="isAdmin" routerLinkActive="active">
            <a (click)="isExpaned = !isExpaned" [class.active]="isExpaned"
              class=" has-arrow has-arrow waves-effect waves-dark" href="javascript:void(0)">
              <i class=" fas fa-user-secret  iconimg"></i><span class="hide-menu"> Admin</span></a>
            <ul class="collapse pt-2" [class.in]="isExpaned">
              <li routerLinkActive="active"><a  routerLink="/def/admin/users-details"><i class="bi bi-person-lines-fill"></i> Users Detail</a></li>
            </ul>
          </li>
          <li routerLinkActive="active">
            <a class="waves-effect waves-dark" routerLink="/def/user/blogs">
              <i class="fas fa-blog" style="font-size: 1.2rem;margin-right: 10px;"></i>
              <span class="hide-menu">Blogs</span></a>
          </li>

          <li routerLinkActive="active">
            <a class="waves-effect waves-dark" routerLink="/def/user/contact">
              <i class=" fas fa-question iconimg"></i>
              <span class="hide-menu"> Help/Contact Us</span></a>
          </li>

        </ul>
      </nav>

      <div class="ps__scrollbar-x-rail" style="left: 0px; bottom: -200px;">
        <div class="ps__scrollbar-x" tabindex="0" style="left: 0px; width: 0px;"></div>
      </div>

      <div class="ps__scrollbar-y-rail" style="top: 200px; height: 217px; right: 0px;">
        <div class="ps__scrollbar-y" tabindex="0" style="top: 29px; height: 32px;"></div>
      </div>

    </div>
  </aside>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
declare var $:any;
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  closePopup: boolean;

  constructor(
    private dialog:MatDialog
  ) { }

  ngOnInit(): void {  
    	//How Work Carousel 
	if ($('.how-work-carousel').length) {
		$('.how-work-carousel').bxSlider({
			pagerCustom: '.pager-one',
			auto:false,
			startSlide: 1,
			infiniteLoop: false,
			easing:'swing',
			adaptiveHeight: true,
			pause: 5000,
			slideMargin: 50,
			captions: true
		});
	}

  this.headerStyle();
  this.openPopup()
}


openPopup(): void {
  const dialogBox = this.dialog.open(PopupComponent, {
    width: '350px',
    disableClose: true
  });
  this.closePopup = dialogBox.disableClose;
  dialogBox.afterClosed().subscribe(result => {
    this.closePopup = false;
    console.log('Popup closed',dialogBox.disableClose = false);
  });
}





headerStyle() {
  if($('.main-header').length){
    var windowpos = $(window).scrollTop();
    var siteHeader = $('.main-header');
    var scrollLink = $('.scroll-to-top');
    if (windowpos >= 1) {
      siteHeader.addClass('fixed-header');
      scrollLink.fadeIn(300);
    } else {
      siteHeader.removeClass('fixed-header');
      scrollLink.fadeOut(300);
    }
  }
}


}

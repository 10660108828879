import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SharingDataService {
  public showLoader = new BehaviorSubject<any>(false);
  

  public CREDIT_PRICE:any=0;
  public USR_NAME:any="";
  public CREDIT_BAL:any=0;
  public TOTAL_CREDIT_BAL:any=0;
  public TOTAL_SEARCH:any=0;
  public PENDING_SEARCH:any=0;
  public CONSUMED_CREDIT:any=0;
 

  userLoginId: any = new BehaviorSubject({ 'loginId': 1, 'role': 'U' });
  public getCreditInfo = new BehaviorSubject<any>({})

  constructor(private userService: UserService, private authservice:AuthService) { 
    if(this.authservice.IS_USR_LOGGED){   
       if(this.USR_NAME == "")  this.getuser();   
    }
    this.getCreditInfo.subscribe((res:any)=>{
      this.CREDIT_BAL = res["balanceCredit"];      
      this.TOTAL_CREDIT_BAL = res["totalCredits"];
      this.CONSUMED_CREDIT = res["usedCredit"]; 
    })
    
  }

  getuser(){   
    this.userService.getUser(this.authservice.USER_ID)
    .subscribe((res:any)=> {
      if(res["type"]=="success"){
        this.USR_NAME = `${res["data"]["firstName"]}`;     
      }     
    });   
  }


 

 /**DICTIONARY WORDS FOR VALIDATED FIELDS IN PROFILE FINDER**/
 dictionary: any = ["image-Image(s)", "name-Name", "classification-Classification", "gender-Gender", "threatLevel-Threat Level", "relatedPersons-Related Persons", "fatherName-Father's Name", "sisterName-Sister's Name", "relatedOrganizations-Related Organization(s)", "daughterName-Daughter's Name", "description-Description", "relatedImage-Related Image(s)", "Related Location(s)", "birthPlace-Place of Birth", "nationalID-National ID", "deathPlace-Place of Death", "deathDate-Date of Death", "alias-Alias", "familyConnections-Connections of Family", "passportNumber-Passport Number", "wifeName-Wife's Name", "husbandName-husband's Name", "motherName-Mother's Name", "brotherName-Brother's Name's", "birthDate-Date of Birth", "sonName-Son's Name", "drivingLicense-Driving License", "comment-comment", "workExperience-Work Experience", "organizations-Organization(s)", "education-Education(s)", "designation-Designation(s)", "placeVisit-Visited Place", "location-Location(s)", "contactInformation-Contact Information", "telephoneNumber-Telephone Number(s)", "facebookID-Facebook IDs", "bankAccountNumber-BankAccount Number(s)", "address-address", "emailID-Email Id(s)", "SocialMediaHead-Social Media Head(s)", "socialMediaHandles-Social Media Handle(s)", "publications-Publication(s)", "otherInfo-Other Info"]

}

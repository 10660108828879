import { environment } from "src/environments/environment";

const register_url = environment.register;
const user_url = environment.user;
const aut_url = environment.auth;
export class AuthApi{
    public static register = register_url + "register/user";
    public static resendOtp = register_url + "register/resendOtp";
    public static verifyUserOtp = register_url + "register/verifyUserOtp";
    
    public static sendPwdResetOtp = user_url + "user/sendPasswordResetOtp";
    public static validateResetPweOtp = user_url + "user/validateResetPasswordOtp";
    public static resetPwd = user_url + "user/resetPassword";

    public static matchPwd = user_url + "user/matchPassword";
    public static veryfyLoginOtp = user_url + "user/sendLoginOtp";
    public static sendLoginOtp = user_url + "user/sendLoginOtp";
    public static validateLoginOtp = user_url + "user/validateLoginOtp";
    public static updatePwd = user_url + "user/updatePassword";
    public static authenticate = "auth/authenticate";
 }
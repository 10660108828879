import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { profilerApis } from '../shared/app_url/profiler_apis_url';
import { UserApi } from '../shared/app_url/user_apis_urls';
@Injectable({
  providedIn: 'root'
})
export class ProfilerDbService {

  constructor(private http: HttpClient) { }


  addKeywordToSearchList(obj: {}): Observable<{}> {
    return this.http.post<{}>(profilerApis.addKeywordToSearchList, obj);
  }

  showAllPhoneEmailSearchList(data: any): Observable<any> {
    return this.http.post<any>(profilerApis.getAllHistoryList, data);
  }
  showAllKeywordFromSearchList(data: any): Observable<any> {
    return this.http.post<any>(profilerApis.showHistoryList, data);
  }


  /**charts apis**/
  searchKeywordHistory(data: {}): Observable<any> {
    return this.http.post<any>(profilerApis.searchKeywordHistory, data);
  }

  searchHistory(data: {}): Observable<any> {
    return this.http.post<any>(profilerApis.searchHistory, data);
  }

  searchStatusHistory(data: {}): Observable<any> {
    return this.http.post<any>(profilerApis.searchStatusHistory, data);
  }

  searchStatusRepository(data: {}): Observable<any> {
    return this.http.post<any>(profilerApis.searchStatusRepository, data);
  }

    showResultOfSearchRepository(data: {}): Observable<{}> {
    return this.http.post<{}>(profilerApis.showResultOfSearchRepository, data);
  }

  deleteRepository(data: {}): Observable<{}> {
    return this.http.post<{}>(profilerApis.deleteRepository, data);
  }


  /**Pending**/
  updateRepository(data: {}): Observable<{}> {
    return this.http.post<{}>(profilerApis.updateRepository, data);
  }

  uploadImageToSearch(data: {}): Observable<{}> {
    return this.http.post<{}>(profilerApis.uploadImage, data);
  }

 
  /* Blogs Apis Here */

  saveBlog(data:{}):Observable<{}>{
    return this.http.post<{}>('http://192.168.2.190:5010/blog',data)
  }


}
